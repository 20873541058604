import {forwardRef, useRef} from 'react'
import {Canvas, extend} from '@react-three/fiber'
import {useSnapshot} from 'valtio'
import {selectedApartmentState} from '../../../state/apartments/selectedApartment'
import * as THREE from 'three'
import WorldPostProcessing from './WorldPostProcessing'
import WorldCamera from './WorldCamera'
import WorldContent from './WorldContent'
import cn from 'classnames'
import styles from './WorldCanvas.module.sass'
import {Leva} from 'leva'

extend(THREE)

//region Default values
const isDesktop = window.innerWidth > 1024
const defaultCameraZoom = isDesktop > 1024
    ? .8
    : 1.5
const maxCameraZoom = isDesktop > 1024
    ? 1.5
    : 2
const pinchMin = 1
const pinchMax = 3
const wheelMin = -2_000
const wheelMax = 0

//endregion

const WorldCanvas = forwardRef(({
                                    hidden,
                                    ...props
                                }, ref) => {

    const selectedApartment = useSnapshot(selectedApartmentState)

    // References
    const canvasRef = useRef(null)
    const worldContentRef = useRef(null)
    const worldCameraRef = useRef(null)

    return <>

        <Canvas
            ref={canvasRef}
            flat
            shadows
            className={cn(styles.world_canvas, {[styles.hidden]: hidden})}
        >
            <fog
                attach={'fog'}
                args={['#EBE7DB', 500, 600]}
            />

            <group
                name={'main_canvas'}
            >
                <WorldCamera
                    cameraZoom={defaultCameraZoom}
                    ref={worldCameraRef}
                />

                <WorldPostProcessing/>

                <WorldContent
                    cameraZoom={defaultCameraZoom}
                    selectedApartment={selectedApartment}

                    ref={worldContentRef}
                />
            </group>
        </Canvas>

        <Leva
            collapsed={true}
            hidden={process.env.NODE_ENV === 'production'}
        />
    </>
})
WorldCanvas.displayName = 'WorldCanvas'

export default WorldCanvas
