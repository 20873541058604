import {proxy} from 'valtio'

export const selectedApartmentState = proxy({
	                                            groupRef:           null,
	                                            apartmentData:      {
		                                            current: null,
	                                            },
	                                            apartmentGroupName: null,
	                                            selectedRoom:       {
		                                            current: null,
	                                            },
                                            })
