import * as THREE from 'three'
import {gsap} from 'gsap/dist/gsap'

const colors = {
    disabled: new THREE.Color('#b3b3b3'), available: new THREE.Color('#9c2525'), hovered: new THREE.Color('#cc0808'),
}

export default class ApartmentBoxGroup extends THREE.Group {
    apaartmentBox = null

    constructor(apartment, functions) {
        super();

        this.position.copy(apartment.position)
        this.rotation.y = apartment.rotation.y

        this.apaartmentBox = new ApartmentBox(apartment, functions)
        this.add(this.apaartmentBox)

        this.rooms = apartment.rooms
        this.stage = apartment.stage
    }

    setDisabled = (disabled) => {
        this.apaartmentBox.setDisabled(disabled)
    }
}

class ApartmentBox extends THREE.Group {
    index = null

    constructor(apartment, functions) {
        super();
        // Create base geometry and material
        const geometry = new THREE.BoxGeometry(7.5, 2, 7)
        const material = new THREE.MeshBasicMaterial({
            color: colors.available, transparent: true, opacity: .75,
        })
        /*const material = new THREE.MeshPhysicalMaterial({
            roughness: 0.7,
            transmission: 1,
            thickness: 1
        })*/
        // Create the mesh
        this.apartment = new THREE.Mesh(geometry, material)
        this.add(this.apartment)
        
        const edges = new THREE.EdgesGeometry(geometry);
        this.line = new THREE.LineSegments(edges, new THREE.LineBasicMaterial({color: 0xffffff}));
        this.add(this.line);

        this.apartment.onClick = this.line.onClick = this.onClick
        this.apartment.onMouseEnter = this.line.onMouseEnter = this.onMouseEnter
        this.apartment.onMouseLeave = this.line.onMouseLeave = this.onMouseLeave
        this.apartment.setDisabled = this.line.setDisabled = this.setDisabled

        this.functions = functions

        // Update the mesh
        this.name = 'ApartmentBox'
        this.aptId = apartment.id
    }

    //region Functions
    setDisabled = (disabled) => {
        if (disabled !== this.disabled) {
            this.disabled = disabled
            if (disabled) {
                const tl = gsap.timeline()
                tl.to(this.apartment.material.color, {
                    r: colors.disabled.r, g: colors.disabled.g, b: colors.disabled.b,
                })
            } else {
                const tl = gsap.timeline()
                tl.to(this.apartment.material.color, {
                    r: colors.available.r, g: colors.available.g, b: colors.available.b,
                })
            }
        }
    }
    //endregion

    //region Handlers
    onClick = () => {
        if (this.disabled) {
            return
        }

        this.functions.animateCamera()

        this.functions.selectApartment(this.aptId)
    }

    onMouseEnter = () => {
        if (this.disabled) {
            return
        }

        if (this.isHovered) {
            return
        }

        this.isHovered = true

        const tl = gsap.timeline()
        tl.to(this.apartment.material.color, {
            r: colors.hovered.r, g: colors.hovered.g, b: colors.hovered.b,
        }, '<')
            .to(this.position, {
                z: .5,
            }, '<')
    }

    onMouseLeave = () => {
        if (this.disabled) {
            return
        }
        if (!this.isHovered) {
            return
        }
        this.isHovered = false

        const tl = gsap.timeline()
        tl.to(this.apartment.material.color, {
            r: colors.available.r, g: colors.available.g, b: colors.available.b,
        }, '<')
            .to(this.position, {
                z: 0,
            }, '<')
    }
    //endregion
}
