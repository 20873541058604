import './styles/globals.sass'
import Plan_Page from './components/Plan_Page'
import React, {forwardRef, useEffect, useMemo, useState} from 'react'
import Actions from './state/actions'
import {generalState} from './state/general'
import {useSnapshot} from 'valtio'
import cn from 'classnames'
import {debugState} from './state/debug'
import mapboxgl from 'mapbox-gl'
import WorldCanvas from './components/3D/World/WorldCanvas'
import Mapbox from './components/3D/Mapbox/Mapbox'
import {selectedApartmentState} from './state/apartments/selectedApartment'
import {selectedProgramState} from './state/selectedProgram'

mapboxgl.accessToken = 'pk.eyJ1IjoibWF0aGlzZnI2MyIsImEiOiJjbDBmZ3puZTcwMDV3M2RxdHZjemZ4dGgxIn0.vsdJgyXayYlDtYVGgKymtw'

export const defaultMapboxOrigin = {
    // Paris center
    center: [2.347283391503538, 48.85485801091036],
    zoom: 10,
    pitch: 0,
    bearing: 0,
}

const debug = window.location.hash === '#debug'
window.debug = debug

const App = forwardRef(({
                            pageToShow,
                        }, ref) => {

    const generalStateSnapshot = useSnapshot(generalState)
    const debugStateSnapshot = useSnapshot(debugState)
    const selectedProgramStateSnapshot = useSnapshot(selectedProgramState)

    //region Define actions
    useEffect(() => {
        Actions()
    }, [])
    //endregion

    //region Window width
    const [windowWidth, setWindowWidth] = useState(0)
    const isDesktop = useMemo(() => windowWidth > 1024, [windowWidth])

    useEffect(() => {
        setWindowWidth(window.innerWidth)
        window.addEventListener('resize', () => {
            setWindowWidth(window.innerWidth)
        })
    }, [])

    useEffect(() => {
        generalState.isDesktop = isDesktop
    }, [isDesktop])
    //endregion

    return (
        <>
            <div className={cn('fullscreen_section', {['grid_css']: debugStateSnapshot.gridModeCSS})}>
                <Mapbox hidden={!!selectedApartmentState.apartmentData.current}/>

                <WorldCanvas
                    hidden={!selectedApartmentState.apartmentData.current}
                />

                {pageToShow === 'plan-page' && generalStateSnapshot.isDesktop && <Plan_Page/>}
            </div>
        </>
    )
})
App.displayName = 'App'

export default App
