import {subscribe}                                  from 'valtio'
import * as THREE                                   from 'three'
import {Vector2, Vector3}                           from 'three'
import {gsap}                                       from 'gsap/dist/gsap'
import {selectedApartmentState}                     from '../apartments/selectedApartment'
import {selectedProgramState, updateProgramOpacity} from '../selectedProgram'
import {generalState}                               from '../general'
import {overlayState}                               from '../overlay'

const moveCameraToApartment = ({
	                               targetData,
	                               cameraData,
	                               rotationY = 0,
	                               timeline = null,
                               }) => {
	// Calculate the x and z coordinates of the camera based on the rotation of the target
	const x = Math.cos(rotationY)
	const z = Math.sin(rotationY)

	const targetPosition = new THREE.Vector3(targetData.position[0], targetData.position[1], targetData.position[2])

	const baseCameraPosition = new THREE.Vector3(0, 6, -10)

	const baseRotatedCameraPosition = new THREE.Vector3(baseCameraPosition.x * x + baseCameraPosition.z * z, baseCameraPosition.y, baseCameraPosition.z * x - baseCameraPosition.x * z)

	const rotatedCameraPosition = baseRotatedCameraPosition.clone()
	                                                       .add(targetPosition)

	if (!timeline) {
		timeline = gsap.timeline()
	}

	timeline
		// Orbit controls -> change target
		.to(generalState.orbitTarget, {
			x: targetData.position[0],
			y: targetData.position[1],
			z: targetData.position[2],
		})
		// Camera
		.to(generalState.camera.position, {
			x: rotatedCameraPosition.x,
			y: rotatedCameraPosition.y,
			z: rotatedCameraPosition.z,
		}, '<')
		.to(generalState.camera.rotation, {
			y: cameraData.rotationY ?? 0,
		}, '<')
}

const moveCameraToRoom = (apartmentData, room) => {
	const apartmentPosition = new THREE.Vector3(
		apartmentData.position.x,
		apartmentData.position.y,
		apartmentData.position.z,
	)
	const room_position     = new THREE.Vector3(
		room.position.x,
		room.position.y,
		room.position.z,
	)
	// Calculate the x and z coordinates of the camera based on the rotation of the target
	const x                 = Math.cos(apartmentData.rotation.y)
	const z                 = Math.sin(apartmentData.rotation.y)

	const rotatedRoomPosition = new THREE.Vector3(
		room_position.x * x + room_position.z * z,
		room_position.y,
		room_position.z * x - room_position.x * z,
	)
	const roomPosition        = apartmentPosition.clone()
	                                             .add(rotatedRoomPosition)

	const cameraPosition = new THREE.Vector3(
		room.camera_position?.x ?? 0,
		room.camera_position?.y ?? 0,
		room.camera_position?.z ?? 0,
	)

	const rotatedCameraPosition = new THREE.Vector3(
		cameraPosition.x * x + cameraPosition.z * z,
		cameraPosition.y,
		cameraPosition.z * x - cameraPosition.x * z,
	)

	const cameraPositionInApartment = roomPosition.clone()
	                                              .add(rotatedCameraPosition)

	const timeline = gsap.timeline()

	timeline
		// Orbit controls -> change target
		.to(
			generalState.orbitTarget,
			{
				x: roomPosition.x,
				y: roomPosition.y,
				z: roomPosition.z,
			},
		)
		// Camera
		.to(
			generalState.camera.position,
			{
				x: cameraPositionInApartment.x,
				y: cameraPositionInApartment.y,
				z: cameraPositionInApartment.z,
			},
			'<',
		)
		.to(generalState.camera.rotation, {
			//			 y: cameraData.rotationY ?? 0,
		}, '<')
}

export const moveCameraOnTopOfSelectedApartment = () => {
	const apartment          = selectedApartmentState.apartmentData.current
	const apartmentRotationY = apartment.rotation.y ?? 0
	const x                  = Math.cos(apartmentRotationY)
	const z                  = Math.sin(apartmentRotationY)

	const baseCameraPosition1 = new THREE.Vector3(0, 10, -4)

	const baseRotatedCameraPosition1 = new THREE.Vector3(baseCameraPosition1.x * x + baseCameraPosition1.z * z, baseCameraPosition1.y, baseCameraPosition1.z * x - baseCameraPosition1.x * z)

	const rotatedCameraPosition1 = baseRotatedCameraPosition1.clone()
	                                                         .add(apartment.position)

	const baseCameraPosition2 = new THREE.Vector3(0, 10, -.1)

	const baseRotatedCameraPosition2 = new THREE.Vector3(baseCameraPosition2.x * x + baseCameraPosition2.z * z, baseCameraPosition2.y, baseCameraPosition2.z * x - baseCameraPosition2.x * z)

	const rotatedCameraPosition2 = baseRotatedCameraPosition2.clone()
	                                                         .add(apartment.position)

	const timeline = gsap.timeline()

	// Set orbit target to center of apartment
	timeline.to(generalState.orbitTarget, {
		x:        apartment.position.x,
		y:        apartment.position.y,
		z:        apartment.position.z,
		duration: 2,
	})

	// Update camera position -> move backward and then focus on center
	timeline.to(generalState.camera.position, {
		x: rotatedCameraPosition1.x,
		y: rotatedCameraPosition1.y,
		z: rotatedCameraPosition1.z,
	}, '<')
	timeline.to(generalState.camera.position, {
		x: rotatedCameraPosition2.x,
		y: rotatedCameraPosition2.y,
		z: rotatedCameraPosition2.z,
	}, '>')
}

const selectionAction = () => {
	subscribe(selectedApartmentState.apartmentData, () => {
		if (selectedApartmentState.apartmentData.current) {
			const apartmentPosition = new THREE.Vector3(selectedApartmentState.apartmentData.current.position.x, selectedApartmentState.apartmentData.current.position.y, selectedApartmentState.apartmentData.current.position.z)
			const cameraPosition    = generalState.camera.position

			const apartmentAndCameraPositionDiff = new THREE.Vector3()
				.subVectors(cameraPosition, apartmentPosition)
			const moveBackPosition               = new THREE.Vector3()
				.copy(cameraPosition)
				.add(apartmentAndCameraPositionDiff
					     .normalize()
					     .multiplyScalar(50),
				)

			const timeline = gsap.timeline({})

			// Move camera backward
			timeline.to(generalState.camera.position, {
				x: moveBackPosition.x,
				y: moveBackPosition.y,
				z: moveBackPosition.z,
			})

			// Hide program
			updateProgramOpacity(timeline)

			overlayState.currentTab = 'apartments'

			// Move camera to the selected apartment
			moveCameraToApartment({
				                      targetData: {position: [selectedApartmentState.apartmentData.current.position.x, selectedApartmentState.apartmentData.current.position.y, selectedApartmentState.apartmentData.current.position.z]},
				                      cameraData: {
					                      rotationY: Math.PI / 4,
				                      },
				                      rotationY:  selectedApartmentState.apartmentData.current.rotation.y,
				                      timeline:   timeline,
			                      })

			timeline.to(selectedApartmentState, {showFloor: true}, '>')
		} else {
			selectedApartmentState.apartmentGroupName = null

			const timeline = gsap.timeline({
				                               defaults: {
					                               duration: 1,
					                               ease:     'none',
				                               },
			                               })

			updateProgramOpacity(timeline)

			// Define program camera position
			const programCameraPosition     = new Vector3(0, 30, 150)
			const programCameraPositionVec2 = new Vector2(programCameraPosition.x, programCameraPosition.z)
			const programTargetPosition     = new Vector2(selectedProgramState.targetPosition[0], selectedProgramState.targetPosition[2])

			const currentCameraPositionVec2 = new Vector2(generalState.camera.position.x, generalState.camera.position.z)

			// Calculate distance between vec2 lineBetweenCameras and point as vec3 programTargetPosition
			const midPosition = getMinimumDistanceToLine(currentCameraPositionVec2, programCameraPositionVec2, programTargetPosition)

			timeline
				.to(selectedApartmentState, {showFloor: false}, '<')
				.to(generalState.orbitTarget, {
					x: selectedProgramState.targetPosition[0],
					y: selectedProgramState.targetPosition[1],
					z: selectedProgramState.targetPosition[2],
				}, '<')
			if (midPosition) {
				timeline.to(generalState.camera.position, {
					x:        midPosition.x,
					z:        midPosition.y,
					duration: .5,
				}, '<')
			}
			timeline.to(
				generalState.camera.position,
				{
					x:        programCameraPosition.x,
					y:        programCameraPosition.y,
					z:        programCameraPosition.z,
					duration: midPosition
					          ? .5
					          : 1,
				},
				midPosition
				? '>'
				: '<',
			)
		}
	})
	subscribe(selectedApartmentState.selectedRoom, () => {
		if (selectedApartmentState.selectedRoom.current) {
			moveCameraToRoom(selectedApartmentState.apartmentData.current, selectedApartmentState.selectedRoom.current)
		}
	})
}

export default selectionAction

function getMinimumDistanceToLine(lineStart, lineEnd, point) {
	const lineDirection  = new THREE.Vector2().subVectors(lineEnd, lineStart)
	const pointDirection = new THREE.Vector2().subVectors(point, lineStart)
	const lineLength     = lineDirection.length()
	lineDirection.normalize()
	const dotProduct            = lineDirection.dot(pointDirection)
	const projectedVector       = lineDirection.clone()
	                                           .multiplyScalar(dotProduct)
	const minimumDistanceVector = new THREE.Vector2().subVectors(pointDirection, projectedVector)
	const minimumDistance       = minimumDistanceVector.length()

	if (minimumDistance < 10) {
		// Calculate a vector representing the minimum distance point on the line from the original point
		const minimumDistancePoint = lineDirection.clone()
		                                          .multiplyScalar(dotProduct)
		                                          .add(lineStart)

		// Calculate a vector representing the direction from the original point to the minimum distance point
		const minimumDistancePointDirection = new THREE.Vector2().subVectors(minimumDistancePoint, point)

		// Calculate the length of the minimum distance point direction vector
		const minimumDistancePointDirectionLength = minimumDistancePointDirection.length()

		// Calculate a new point that is 50 units away from the line in the opposite direction from the original point
		const newPointDirection = minimumDistancePointDirection.clone()
		                                                       .normalize()
		                                                       .multiplyScalar(-50)
		const newPoint          = new THREE.Vector2().addVectors(point, newPointDirection)

		return newPoint
	}

	return null // Return null if the minimum distance is >= 10
}
