import {forwardRef, useRef}     from 'react'
import Apartment                from '../Apartment/Apartment'
import {useSnapshot}            from 'valtio'
import {selectedApartmentState} from '../../../state/apartments/selectedApartment'
import {selectedProgramState}   from '../../../state/selectedProgram'
import Lights                   from './Lights'


const WorldContent = forwardRef(({
	                                 cameraZoom,
	                                 ...props
                                 }, ref) => {

	const selectedApartment = useSnapshot(selectedApartmentState)
	const selectedProgram   = useSnapshot(selectedProgramState)

	const selectedProgramGroupRef   = useRef()
	const selectedApartmentGroupRef = useRef()

	return (
		<group
			name={'World content'}
			ref={ref}
		>
			<Lights/>

			<group
				name={selectedApartment.apartmentData.current?.apartment.data.attributes.name}
				key={selectedApartment.apartmentData.current?.id}
				ref={selectedApartmentGroupRef}
			>
				{selectedApartment.apartmentData.current?.apartment.data.attributes.glb_file &&
				 <Apartment
					 position={[selectedApartment.apartmentData.current?.position.x, selectedApartment.apartmentData.current?.position.y, selectedApartment.apartmentData.current?.position.z]}
					 rotation={[selectedApartment.apartmentData.current?.rotation.x, selectedApartment.apartmentData.current?.rotation.y, selectedApartment.apartmentData.current?.rotation.z]}
				 />}
			</group>
		</group>
	)
})

WorldContent.displayName = 'World content'

export default WorldContent
