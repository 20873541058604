import {useSnapshot}                        from 'valtio'
import {selectedApartmentState}             from '../../state/apartments/selectedApartment'
import {apartmentsState}                    from '../../state/apartments/apartments'
import {useEffect, useMemo}                 from 'react'
import gsap                                 from 'gsap/dist/gsap'
import cn                                   from 'classnames'
import styles                               from '../../styles/components/overlays/Apartment.module.sass'
import {FaChevronLeft}                      from 'react-icons/fa'
import {overlayState}                       from '../../state/overlay'
import {moveCameraOnTopOfSelectedApartment} from '../../state/actions/selection'

const ApartmentOverlay = ({
	                          pageToShow,
	                          setPageToShow,
	                          setShowIframe,
	                          setShowContactForm,
                          }) => {

	// Global states
	const selectedApartment    = useSnapshot(selectedApartmentState)
	const apartments           = useSnapshot(apartmentsState)
	const overlayStateSnapshot = useSnapshot(overlayState)

	//region Data
	// Rooms
	const numberOfRooms = useMemo(
		() => {
			return [
				{
					title:   'Studio',
					enabled: true,
				}, {
					title:   '2',
					enabled: false,
				}, {
					title:   '3',
					enabled: true,
				}, {
					title:   '4',
					enabled: true,
				}, {
					title:   '5',
					enabled: true,
				},
			]
		},
		[],
	)

	// Stages
	const stages = useMemo(
		() => {
			return [
				{
					title:   'RdC',
					enabled: true,
				}, {
					title:   '1',
					enabled: true,
				}, {
					title:   '2',
					enabled: true,
				}, {
					title:   '3',
					enabled: true,
				}, {
					title:   '4',
					enabled: true,
				}, {
					title:   '5',
					enabled: true,
				}, {
					title:   '6',
					enabled: false,
				}, {
					title:   '7',
					enabled: true,
				},
			]
		},
		[],
	)
	//endregion

	//region States
	// At least one filter selected
	const atLeastOneFilterSelected = useMemo(
		() => {
			const atLeastOneFilterSelected = apartments.filters.selectedNumberOfRooms !== null || apartments.filters.selectedStage !== null

			/*Change iFrame with an image to select apartments*/
			setShowIframe(!atLeastOneFilterSelected)

			return atLeastOneFilterSelected
		},
		[
			apartments.filters, overlayStateSnapshot.currentTab,
		],
	)
	//endregion

	//region Handlers
	// Handle filter selection
	useEffect(
		() => {
			/*Transition between global information panes*/
			gsap.to(
				`.apartments_global_information`,
				{
					xPercent: atLeastOneFilterSelected
					          ? 0
					          : -50,
				},
			)
		},
		[
			atLeastOneFilterSelected,
		],
	)

	// Handle page to show change
	useEffect(
		() => {
			/*Animation between two overlay panes*/
			gsap.to(
				`.apartments_content`,
				{
					xPercent: !selectedApartment.apartmentData.current
					          ? 0
					          : -50,
				},
			)
		},
		[selectedApartment.apartmentData.current],
	)
	//endregion

	return <div
		className={cn(
			styles.apartments_content,
			'apartments_content',
		)}
		style={{
			display: overlayStateSnapshot.currentTab === 'apartments'
			         ? 'flex'
			         : 'none',
		}}
	>
		{/*Building pane*/}
		<div className={styles.building_pane}>
			{/*Apartments global information*/}
			<div
				className={cn(
					styles.apartments_global_information,
					'apartments_global_information',
				)}
			>
				{/*Information displayed when there is no filter selected*/}
				<div className={styles.no_filter}>
					{/*Number of apartments value*/}
					<span className={styles.number_of_apartments}>48</span>

					{/*Text*/}
					<span className={styles.text}>Logements disponibles</span>

					{/*Number of rooms interval and surface interval*/}
					<span className={styles.rooms_and_surface_interval}>Surface: de 24m² à 117m²</span>

					{/*Price interval*/}
					<span className={styles.price_interval}>Prix : de <em>163 000 €</em> à <em>477 000 €</em></span>
				</div>

				{/*Information displayed when there is a filter selected*/}
				<div className={styles.filter}>
					{/*Number of apartments value*/}
					<span className={styles.number_of_apartments}>6</span>

					{/*Text*/}
					<span className={styles.text}>Logements {(
						                                         !isNaN(numberOfRooms[apartments.filters.selectedNumberOfRooms]?.title) && isFinite(numberOfRooms[apartments.filters.selectedNumberOfRooms]?.title)
					                                         )
					                                         ? <>de <em>{numberOfRooms[apartments.filters.selectedNumberOfRooms]?.title ?? 0} pièces</em></>
					                                         : <em>{numberOfRooms[apartments.filters.selectedNumberOfRooms]?.title}</em>} disponibles</span>

					{/*Number of rooms interval and surface interval*/}
					<span className={styles.rooms_and_surface_interval}>Surface: de 56m² à 74m²</span>

					{/*Price interval*/}
					<span className={styles.price_interval}>Prix : de <em>255 300 €</em> à <em>477 000 €</em></span>
				</div>
			</div>

			{/*Filter available apartments*/}
			<div
				className={cn(
					styles.filter_available_apartments,
					{[styles.is_shown]: overlayStateSnapshot.filtersShown},
				)}
			>
				{/*Title*/}
				<h4 className={styles.title}>Filtres</h4>

				{/*Type (rooms)*/}
				<div className={styles.type}>
					{/*Title*/}
					<span className={styles.title}>Nombre de pièces</span>

					{/*List*/}
					<div className={styles.list}>
						{numberOfRooms.map((item, index) => (
							<div
								key={index}
								className={cn(
									styles.item,
									{
										[styles.selected]: apartments.filters.selectedNumberOfRooms === index + 1,
										[styles.disabled]: !item.enabled,
									},
								)}

								//region Handlers
								// On click -> change selected room (if the clicked room is enabled)
								onClick={() => {
									if (item.enabled) {
										apartmentsState.filters.selectedNumberOfRooms =
											apartmentsState.filters.selectedNumberOfRooms === index + 1
											? null
											: index + 1
									}
								}}
								//endregion
							>
								<span className={styles.title}>{item.title}</span>
							</div>
						))}

					</div>
				</div>

				{/*Stage*/}
				<div className={styles.stage}>
					{/*Title*/}
					<span className={styles.title}>Étage</span>

					{/*List*/}
					<div className={styles.list}>
						{stages.map((item, index) => (
							<div
								key={index}
								className={cn(
									styles.item,
									{
										[styles.selected]: apartments.filters.selectedStage === index ?? false,
										[styles.disabled]: !item.enabled,
									},
								)}

								//region Handlers
								// On click -> change selected stage (if the clicked stage is enabled)
								onClick={() => {
									if (item.enabled) {
										apartmentsState.filters.selectedStage =
											apartmentsState.filters.selectedStage === index
											? null
											: index
									}
								}}
								//endregion
							>
								<span className={styles.title}>{item.title}</span>
							</div>
						))}
					</div>
				</div>
			</div>
		</div>

		{/*Apartment pane*/}
		<div className={styles.apartment_pane}>
			{/*General apartment information*/}
			<div className={styles.general_apartment_information}>
				{/*Go back button*/}
				<div
					className={styles.go_back_button}

					/*region Handlers*/
					/*On click -> Set page to show to building page*/
					onClick={() => {
						selectedApartmentState.apartmentData.current = null
						selectedApartmentState.apartmentGroupName    = null
					}}
					/*endregion*/
				>
					<FaChevronLeft className={styles.icon}/>
				</div>

				{/*Content*/}
				<div className={styles.content}>
					{/*Package number*/}
					<span className={styles.package_number}>Lot n°2234</span>

					{/*Type*/}
					<span className={styles.type}>Appartement</span>

					{/*Rooms and surface*/}
					<span className={styles.rooms_and_surface}>4 pièces · 75m²</span>

					{/*Stage and parking*/}
					<span className={styles.stage_and_parking}>Étage 2 · Parking</span>
				</div>
			</div>

			{/*Bottom content*/}
			<div className={styles.bottom_content}>
				{/*Rooms selection*/}
				<div
					className={cn(
						styles.rooms_selection,
						{[styles.is_shown]: overlayStateSnapshot.roomSelectionShown},
					)}
				>
					{/*Top section*/}
					<div className={styles.top_section}>
						{/*Title*/}
						<span className={styles.title}>Pièces</span>

						{/*Recenter*/}
						<span
							className={styles.recenter}
							onClick={() => {
								moveCameraOnTopOfSelectedApartment()
							}}
						>Recentrer</span>
					</div>

					{/*List*/}
					<div className={styles.list}>
						{/*Get rooms except first one*/}
						{selectedApartment.apartmentData.current?.apartment.data.attributes.cameras
						                  .map((item, index) => (
							                  <div
								                  className={cn(
									                  styles.item,
									                  {
										                  [styles.selected]: selectedApartment.selectedRoom.current?.id === item.id,
									                  },
								                  )}
								                  key={index}

								                  /*region Handlers*/
								                  /*On click -> Set selected room*/
								                  onClick={() => {
									                  selectedApartmentState.selectedRoom.current = item
								                  }}
								                  /*endregion*/
							                  >
								                  {/*Title*/}
								                  <span className={styles.title}>{item.name}</span>
							                  </div>
						                  ))}
					</div>
				</div>

				{/*See price and plan button*/}
				<button
					className={styles.see_price_and_plan}

					/*region Handlers*/
					/*On click -> Show contact form overlay*/
					onClick={() => {
						setShowContactForm(true)
					}}
					/*endregion*/
				>Consulter le prix et le plan
				</button>
			</div>
		</div>
	</div>
}

export default ApartmentOverlay
