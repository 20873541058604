import * as THREE from 'three'
import {DRACOLoader} from 'three/addons/loaders/DRACOLoader'
import {GLTFLoader} from 'three/addons/loaders/GLTFLoader'
import mapboxgl from 'mapbox-gl'
import ApartmentBoxGroup from './ApartmentBox'

export class MapboxThreeJS {
    //region Properties
    type = 'custom'
    renderingMode = '3d'
    //endregion

    //region Constructor
    constructor(program, functions) {
        this.id = program.id
        this.program = program
        this.functions = functions
    }

    //endregion

    //region Add ThreeJS to Mapbox
    onAdd = async (map, gl) => {
        this.map = map

        // use the Mapbox GL JS map canvas for three.js
        this.renderer = new THREE.WebGLRenderer({
            canvas: map.getCanvas(), context: gl, antialias: true,
        })
        this.renderer.outputEncoding = THREE.sRGBEncoding

        this.renderer.autoClear = false

        //region Raycaster
        this.raycaster = new THREE.Raycaster()
        this.raycaster.near = -1
        this.raycaster.far = 1e6
        //endregion

        this.camera = new THREE.PerspectiveCamera(28, window.innerWidth / window.innerHeight, 0.1, 1e6)
        this.scene = new THREE.Scene()

        //region Loaders
        const dracoLoader = new DRACOLoader()
        dracoLoader.setDecoderPath('/draco/')
        this.loaderWithDraco = new GLTFLoader()
        this.loaderWithDraco.setDRACOLoader(dracoLoader)
        //endregion

        //region Groups
        // General group
        this.generalGroup = new THREE.Group()
        this.generalGroup.rotation.y = this.program.attributes.Camera.rotationY
        this.scene.add(this.generalGroup)

        // Building group
        this.buildingGroup = new THREE.Group()
        this.generalGroup.add(this.buildingGroup)

        // Lights group
        this.lightsGroup = new THREE.Group()
        this.generalGroup.add(this.lightsGroup)
        //endregion

        //region Lights
        // Ambient light
        const ambientLight = new THREE.AmbientLight('#ffefe6', .55)
        this.lightsGroup.add(ambientLight)

        // Directional light
        const directionalLight = new THREE.DirectionalLight('#fff7f2', .1)
        directionalLight.position.set(7, 90, -13)
        this.lightsGroup.add(directionalLight)
        //endregion

        this.addProgram()

        //region Events
        map.on('click', event => {
            this.raycast(event.point, e => {

                let element = e

                while (!!element && element?.onClick === undefined) {
                    element = element.parent
                }

                if (element?.onClick !== undefined) {
                    element.onClick()
                }
            })
        })

        map.on('mousemove', event => {
            this.raycast(event.point, e => {
                if (e?.onMouseEnter !== undefined) {
                    e.onMouseEnter()
                }
            })
        })
        //endregion
    }
    //endregion

    //region Render
    render = (gl, matrix) => {
        const modelRotate = [Math.PI / 2, 0, 0]

        const center = [this.program.attributes.Camera.Center.x, this.program.attributes.Camera.Center.y]
        const modelAsMercatorCoordinate = mapboxgl.MercatorCoordinate.fromLngLat(center, 0)

        // Rotation matrix
        const rotationX = new THREE.Matrix4().makeRotationAxis(new THREE.Vector3(1, 0, 0), modelRotate[0])
        const rotationY = new THREE.Matrix4().makeRotationAxis(new THREE.Vector3(0, 1, 0), modelRotate[1])
        const rotationZ = new THREE.Matrix4().makeRotationAxis(new THREE.Vector3(0, 0, 1), modelRotate[2])

        // Translation matrix
        const translation = new THREE.Matrix4().makeTranslation(modelAsMercatorCoordinate.x, modelAsMercatorCoordinate.y, modelAsMercatorCoordinate.z,)

        // Scale matrix
        const scaleValue = modelAsMercatorCoordinate.meterInMercatorCoordinateUnits()
        const scale = new THREE.Matrix4().makeScale(scaleValue, -scaleValue, scaleValue,)

        const m = new THREE.Matrix4().fromArray(matrix)
        const l = new THREE.Matrix4()
            .multiply(translation)
            .multiply(scale)
            .multiply(rotationX)
            .multiply(rotationY)
            .multiply(rotationZ)

        this.camera.position.set(0, 0, 0)
        this.camera.lookAt(new THREE.Vector3(0, 0, 0))

        this.camera.projectionMatrix = m.multiply(l)
        this.renderer.resetState()
        this.renderer.render(this.scene, this.camera)
        this.map.triggerRepaint()
    }
    //endregion

    //region Raycast
    raycast = (point, callback) => {
        var mouse = new THREE.Vector2()
        // // scale mouse pixel position to a percentage of the screen's width and height
        mouse.x = (point.x / this.map.transform.width) * 2 - 1
        mouse.y = 1 - (point.y / this.map.transform.height) * 2

        const camInverseProjection = new THREE.Matrix4().copy(this.camera.projectionMatrix)
            .invert()
        const cameraPosition = new THREE.Vector3().applyMatrix4(camInverseProjection)
        const mousePosition = new THREE.Vector3(mouse.x, mouse.y, 1).applyMatrix4(camInverseProjection)
        const viewDirection = mousePosition.clone()
            .sub(cameraPosition)
            .normalize()

        this.raycaster.set(cameraPosition, viewDirection)

        // calculate objects intersecting the picking ray
        var intersects = this.raycaster.intersectObjects(this.scene.children, true)
        if (intersects.length) {
            // Get the first element that has been intersected
            const intersectElement = intersects[0].object

            // Leave previous hovered object
            if (this.hoveredObject !== intersectElement) {
                if (this.hoveredObject?.onMouseLeave !== undefined) {
                    this.hoveredObject?.onMouseLeave()
                }
            }

            // Change hovered object
            this.hoveredObject = intersectElement

            // If the element has an onClick function, call it
            callback(intersectElement)
        }
    }
    //endregion

    //region Functions
    addProgram = () => {
        //region Program model
        this.loaderWithDraco.load(this.program.attributes.glb_file, (gltf) => {
            gltf.scene.onClick = () => {
                this.moveCameraToProgram()
                this.functions.setSelectedProgram()
            }
            gltf.scene.name = 'Program'
            this.buildingGroup.add(gltf.scene)
        })
        //endregion

        //region Apartments boxes
        this.apartmentBoxes = new THREE.Group()
        this.buildingGroup.add(this.apartmentBoxes)
        this.program.attributes.apartments.forEach((apartment) => {
            const functions = {
                animateCamera: () => {
                    this.animateCamera()
                }, selectApartment: (id) => {
                    this.functions.setSelectedProgram()

                    setTimeout(() => {
                        this.functions.selectApartment(id)
                    }, 1000)
                }
            }
            const boxGroup = new ApartmentBoxGroup(apartment, functions)
            this.apartmentBoxes.add(boxGroup)
        })
        //endregion
    }

    filterApartments = (filters) => {
        this.apartmentBoxes.children.forEach(apartmentBox => {
            let disabled = false
            if (filters.selectedNumberOfRooms) {
                disabled = disabled || apartmentBox.rooms !== filters.selectedNumberOfRooms
            }
            if (filters.selectedStage) {
                disabled = disabled || apartmentBox.stage !== filters.selectedStage
            }

            apartmentBox.setDisabled(disabled)
        })
    }

    //region Camera
    getCameraPosition = () => {
        const camInverseProjection = new THREE.Matrix4()
            .copy(this.camera.projectionMatrix)
            .invert()

        return new THREE.Vector3().applyMatrix4(camInverseProjection)
    }

    setCameraPosition = (position, altitude, target) => {
        const camera = this.map.getFreeCameraOptions()

        camera.position = mapboxgl.MercatorCoordinate.fromLngLat(position, altitude,)
        camera.lookAtPoint(target)

        this.map.setFreeCameraOptions(camera)
    }

    animateCamera = () => {
        const zoom = this.map.getZoom()
        const totalDuration = 1350
        const moveAwayDuration = totalDuration * .75
        this.map.easeTo({
            zoom: zoom - .25, duration: moveAwayDuration,
        })
        setTimeout(() => {
            this.map.easeTo({
                zoom: zoom + 1, duration: totalDuration - moveAwayDuration
            })
        }, moveAwayDuration)
    }

    moveCameraToProgram = () => {
        this.map.flyTo({
            center: [this.program.attributes.Camera.Center.x, this.program.attributes.Camera.Center.y],
            zoom: this.program.attributes.Camera.zoom,
            pitch: this.program.attributes.Camera.pitch,
            bearing: this.program.attributes.Camera.bearing,
        })
    }
    //endregion
    //endregion
}
