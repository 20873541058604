import styles from '../styles/Layout.module.sass'
import {useEffect, useMemo, useRef, useState} from 'react'
import App from '../App'
import Overlay from './Overlay'
import axios from 'axios'
import qs from 'qs'
import {useGLTF} from '@react-three/drei'
import cn from 'classnames'
import Header from './Header'
import Footer from './Footer'
import {useSnapshot} from 'valtio'
import {selectedProgramState} from '../state/selectedProgram'
import {selectedApartmentState} from '../state/apartments/selectedApartment'
import {generalState} from '../state/general'
import BottomRightOverlays from './overlays/bottom_right/BottomRightOverlays'
import {programsState} from '../state/programs'
//region Images
//region Equipments

//endregion
//region Transports import {Overlay} from './Overlay'
//endregion
//endregion

const getPrograms = async () => {
    axios.defaults.headers.common['Authorization'] = `Bearer ${process.env.REACT_APP_API_TOKEN}`

    const query = qs.stringify({
        fields: ['name'], populate: {
            model: {
                fields: ['url'],
            },

            apartments: {
                fields: ['stage', 'rooms',],

                populate: {
                    apartment: {
                        fields: ['title'], populate: {
                            glb_file: {
                                fields: ['id', 'url',],
                            },

                            cameras: {
                                fields: ['*',], populate: {
                                    position: {
                                        fields: ['x', 'y', 'z'],
                                    }, rotation: {
                                        fields: ['x', 'y', 'z'],
                                    }, camera_position: {
                                        fields: ['x', 'y', 'z'],
                                    },
                                },
                            },
                        },
                    },

                    position: {
                        fields: ['x', 'y', 'z'],
                    }, rotation: {
                        fields: ['x', 'y', 'z'],
                    },
                },
            }, Camera: {
                fields: ['zoom', 'pitch', 'bearing', 'buildingToRemove', 'rotationY'], populate: {
                    Center: {
                        fields: ['x', 'y'],
                    },
                },
            },
        },
    }, {encodeValuesOnly: true})

    const programs = await axios.get(`${process.env.REACT_APP_STRAPI_API}/api/programmes?${query}`)
        .then(res => {
            const programs = res.data.data

            programs.forEach(program => {
                // Set glb file
                program.attributes.glb_file = `${process.env.REACT_APP_STRAPI_API}${program.attributes.model.data.attributes.url}`

                // Preload GLB File
                useGLTF.preload(program.attributes.glb_file)

                // Preload apartment GLB Files
                program.attributes.apartments.forEach(apartmentData => {
                    apartmentData.apartment.data.attributes.glb_file = `${process.env.REACT_APP_STRAPI_API}${apartmentData.apartment.data.attributes.glb_file.data.attributes.url}`
                })
            })

            return programs
        })

    return programs
}

const Layout = () => {

    const selectedApartment = useSnapshot(selectedApartmentState)
    useSnapshot(selectedProgramState)
    useSnapshot(programsState)
    useSnapshot(generalState)
    const appRef = useRef()

    //region States
    const [pageToShow, setPageToShow] = useState('building-page')

    // If true, show the iframe, else show an image with some overlay representing selectable apartments
    const [showIframe, setShowIframe] = useState(true)

    // Program data
    const [selectedRoom, setSelectedRoom] = useState(null)

    // Is mobile
    const [windowWidth, setWindowWidth] = useState(false)
    const isMobile = useMemo(() => {
        return windowWidth < 1025
    })
    useEffect(() => {
        setWindowWidth(window.innerWidth)
        window.addEventListener('resize', () => {
            setWindowWidth(window.innerWidth)
        })
    }, [])
    //endregion

    //region Handlers
    // Component load -> Get all rooms data
    useEffect(() => {
        const loadRooms = async () => {
            const programs = await getPrograms()

            //region DEBUG program camera
            /*const indexToDebug = 1
            programs[indexToDebug].attributes.Camera.Center = {
                x: 2.2559, y: 48.83525
            }
            programs[indexToDebug].attributes.Camera.zoom = 20
            programs[indexToDebug].attributes.Camera.pitch = 70
            programs[indexToDebug].attributes.Camera.bearing = 135
            programs[indexToDebug].attributes.Camera.rotationY = Math.PI / 2 - Math.PI / 4*/
            //endregion

            //region DEBUG program apartment
            /*const indexToDebug = 0
            programs[indexToDebug].attributes.apartments[0].position = {
                x: -11.5, y: 14.389257954647238, z: 0,
            }
            programs[indexToDebug].attributes.apartments[0].rotation.y = Math.PI + Math.PI / 6.3
            programs[indexToDebug].attributes.apartments[1].position = {
                x: -18.3, y: 14.389257954647238, z: 3.7,
            }
            programs[indexToDebug].attributes.apartments[1].rotation.y = Math.PI + Math.PI / 6.3
            programs[indexToDebug].attributes.apartments[2].position = {
                x: -25.1, y: 14.389257954647238, z: 7.4,
            }
            programs[indexToDebug].attributes.apartments[2].rotation.y = Math.PI + Math.PI / 6.3*/
            //endregion

            // Update zoom level if we are on mobile
            if (isMobile) {
                programs.forEach(p => {
                    p.attributes.Camera.zoom = p.attributes.Camera.zoom - 1
                })
            }

            programsState.current = programs
        }

        loadRooms()
            .then()
    }, [])

    // Selected apartment changed
    useEffect(() => {
        if (selectedApartment.apartmentData.current) {
            generalState.currentTab = 'apartments'
        } else {
            generalState.currentTab = 'program'
        }
    }, [selectedApartment.apartmentData.current])
    //endregion

    return (<div
        className={cn(styles.page_content, styles.fullscreen, {
            [styles.is_plan_page]: pageToShow === 'plan-page',
        })}
    >
        {/*Header*/}
        <Header/>

        {/*Content with current page component*/}
        <main>
            <Overlay
                pageToShow={pageToShow}
                setPageToShow={setPageToShow}
                showIframe={showIframe}
                setShowIframe={setShowIframe}
            />
            <App
                pageToShow={pageToShow}
                ref={appRef}
            />

            {/*List of apartments*/}
            {/*<ApartmentsList/>*/}

            <BottomRightOverlays/>
        </main>

        <Footer/>
    </div>)
}
export default Layout
