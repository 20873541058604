import React, {useEffect, useRef, useState} from 'react'

import mapboxgl from 'mapbox-gl'
import 'mapbox-gl/dist/mapbox-gl.css'

import {MapboxThreeJS} from './MapboxThreeJS'
import {defaultMapboxOrigin} from '../../../App'
import cn from 'classnames'
import styles from './Mapbox.module.sass'
import {useSnapshot} from 'valtio'
import {selectedProgramState} from '../../../state/selectedProgram'
import {apartmentsState} from '../../../state/apartments/apartments'
import {programsState} from '../../../state/programs'
import {selectedApartmentState} from "../../../state/apartments/selectedApartment"

const Mapbox = ({
                    hidden,
                }) => {
    //region Refs and states
    // Refs
    const mapContainer = useRef(null)
    const map = useRef(null)
    const programsCanvasRef = useRef([])
    const selectedProgramRef = useRef(null)

    // States
    const [selectedProgramCanvas, setSelectedProgramCanvas] = useState(null)
    //endregion

    //region Snapshots
    const programsStateSnapshot = useSnapshot(programsState)
    const selectedProgramStateSnapshot = useSnapshot(selectedProgramState)
    const apartmentsStateSnapshot = useSnapshot(apartmentsState)
    useSnapshot(selectedApartmentState)
    //endregion

    //region Functions
    const goToTopView = () => {
        map.current.scrollZoom.disable()
        map.current.flyTo({
            pitch: 0, bearing: 0, duration: 1000,
        })
        setTimeout(() => {
            map.current.scrollZoom.enable()
        }, 1000)
    }

    const setMarkersHidden = (hidden) => {
        if (hidden) {
            const markers = document.querySelectorAll('.mapboxgl-marker')
            markers.forEach((marker) => {
                marker.classList.add('hidden')
            })
        } else {
            const markers = document.querySelectorAll('.mapboxgl-marker')
            markers.forEach((marker) => {
                marker.classList.remove('hidden')
            })
        }
    }
    //endregion

    //region Handlers
    //region On render
    useEffect(() => {
        //region Mapbox
        if (map.current) {
            return
        }

        map.current = new mapboxgl.Map({
            // Choose from Mapbox's core styles, or make your own style with Mapbox Studio
            style: 'mapbox://styles/mathisfr63/cljgya6d500f601pl3cs1be43', ...{...defaultMapboxOrigin},
            container: 'map-container',
            antialias: true,
        })

        //region Events
        //region Style load
        map.current.on('style.load', () => {
            // Update building 3D layer paint : do not show disabled buildings
            map.current.setPaintProperty('building-extrusion', 'fill-extrusion-height', ['interpolate', ['linear'], ['zoom'], 15, 0, 15.05, ['case', ['boolean', ['feature-state', 'disabled'], false], 0, ['get', 'height'],],])
        })
        //endregion

        //region Wheel
        const onTouchEndAndWheel = () => {
            // Get map current zoom
            const zoom = map.current.getZoom()

            if (selectedProgramRef.current) {
                if (zoom < 15) {
                    selectedProgramState.programData.current = null

                    goToTopView()
                }
            } else {
                setMarkersHidden(zoom > 15)
            }
        }
        map.current.on('wheel', onTouchEndAndWheel)
        map.current.on('touchend', onTouchEndAndWheel)
        //endregion

        //region Click
        /*if (process.env.NODE_ENV !== 'production') {
            map.current.on('click', (e) => {
                console.log('Point on map:', e.lngLat)

                const camera = {
                    zoom: map.current.getZoom(),
                    center: map.current.getCenter(),
                    pitch: map.current.getPitch(),
                    bearing: map.current.getBearing(),
                }
                console.log("Camera -> ", camera)

                // Get clicked building id
                const features = map.current.queryRenderedFeatures(e.point)

                // Loop through the features to find the building feature
                for (const feature of features) {
                    console.log(feature)
                    // Check if the feature belongs to your 3D layer containing buildings
                    if (feature.layer.id === 'add-3d-buildings') {
                        // Get the building ID
                        const buildingId = feature.id
                        console.log('Building ID:', buildingId)
                        break
                    }
                }
            })
        }*/
        //endregion
        //endregion
    }, [])
    //endregion

    //region On filter change
    useEffect(() => {
        selectedProgramCanvas?.filterApartments(apartmentsStateSnapshot.filters)
    }, [apartmentsStateSnapshot.filters])
    //endregion

    //region On selected program change or unselect selected apartment
    useEffect(() => {
        const program = selectedProgramStateSnapshot.programData.current
        selectedProgramRef.current = program
        if (program && !selectedApartmentState.apartmentData.current) {
            const canvas = programsCanvasRef.current.find((canvas) => canvas.program.id === program?.id)?.canvas
            setSelectedProgramCanvas(canvas)
            const mapboxProgramCamera = program?.attributes.Camera
            if (mapboxProgramCamera) {
                map.current.flyTo({
                    center: [mapboxProgramCamera.Center.x, mapboxProgramCamera.Center.y],
                    zoom: mapboxProgramCamera.zoom,

                    bearing: mapboxProgramCamera.bearing,
                    pitch: mapboxProgramCamera.pitch,

                    // duration: 3000,
                    duration: 500,
                })
            }

            // Hide markers
            setMarkersHidden(true)
        } else {
            // Show markers
            setMarkersHidden(false)
        }
    }, [selectedProgramStateSnapshot.programData.current, selectedApartmentState.apartmentData.current])
    //endregion

    //region On programs change
    useEffect(() => {
        programsStateSnapshot.current.forEach((program, index) => {
            const programCamera = program.attributes.Camera
            //region Add ThreeJS layer
            // Set layer functions
            const functions = {
                setSelectedProgram() {
                    selectedProgramState.programData.current = program
                }, selectApartment(id) {
                    selectedApartmentState.apartmentData.current = selectedProgramRef.current?.attributes.apartments.find((apartment) => apartment.id === id)
                }
            }
            const programCanvas = new MapboxThreeJS(program, functions)
            programsCanvasRef.current.push({
                program: program, canvas: programCanvas,
            })
            map.current.addLayer(programCanvas)

            // Remove buildings that are replaced by 3D models
            map.current.setFeatureState({
                source: 'composite', sourceLayer: 'building', id: programCamera.buildingToRemove,
            }, {disabled: true},)
            //endregion

            //region Add marker
            const marker = new mapboxgl.Marker({color: "red", draggable: false})
                .setLngLat({lng: programCamera.Center.x, lat: programCamera.Center.y})
                .addTo(map.current);

            // Add click event
            marker.getElement().addEventListener('click', () => {
                selectedProgramState.programData.current = program
            });
            //endregion
        })

        // selectedProgramState.programData.current = programsStateSnapshot.current[0]
    }, [programsStateSnapshot.current])
    //endregion
    //endregion

    return (<div
        ref={mapContainer}
        id={'map-container'}
        className={cn(styles.mapbox, {[styles.hidden]: hidden})}
    />)
}

export default Mapbox
