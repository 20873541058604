import React           from 'react'
import ReactDOM        from 'react-dom/client'
import './index.css'
import reportWebVitals from './reportWebVitals'

// New
import Layout from './components/Layout'
// Register the THREE namespace as native JSX elements.
// See below for notes on tree-shaking


const root = ReactDOM.createRoot(document.getElementById('z_three_js_container'))
root.render(
	/* <React.StrictMode> */
	<Layout/>,
	/* </React.StrictMode> */
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
