//region Imports
import {useEffect, useMemo, useState}  from 'react'
import styles                          from '../styles/Overlay.module.sass'
import cn                              from 'classnames'
import {FaCamera, FaCaretLeft, FaHome} from 'react-icons/fa'
//region Assets
//region Equipments
import EquipmentElevator               from '../images/assets/elevator.svg'
import EquipmentDigicode               from '../images/assets/digicode.svg'
import EquipmentInterphone             from '../images/assets/interphone.svg'
import EquipmentEquipedKitchen         from '../images/assets/cuisine.svg'
import EquipmentCollectiveHeating      from '../images/assets/chaleur.svg'
import EquipmentGreenSpaces            from '../images/assets/green_spaces.svg'
import EquipmentTerrace                from '../images/assets/terrace.svg'
//endregion
//region Transports
import TransportLineL                  from '../images/assets/bus.png'
import TransportBus45                  from '../images/assets/ligne-l@2x.png'
//endregion
//region Neighborhood
//region Transports
//endregion
//endregion
import EugenieIcon                     from '../images/assets/Eugenie.svg'
//endregion
import gsap                            from 'gsap/dist/gsap'
import {Swiper, SwiperSlide}           from 'swiper/react'
import {Pagination}                    from 'swiper'
import 'swiper/css'
import 'swiper/css/pagination'

import 'react-photoswipe/lib/photoswipe.css'
import {PhotoSwipe}                    from 'react-photoswipe'
import ApartmentOverlay                from './overlays/Apartment'
import OverlayNeighborhoodContent      from './overlays/Neighbor'
import OverlayPriceAndPlan             from './overlays/PriceAndPlan'
import OverlayContactUs                from './overlays/ContactUs'
import {useSnapshot}                   from 'valtio'
import {overlayState}                  from '../state/overlay'
import {generalState}                  from '../state/general'
import Plan_Page                       from './Plan_Page'
import {selectedApartmentState}        from '../state/apartments/selectedApartment'
//endregion

const Overlay = ({
	                 pageToShow,
	                 setPageToShow,
	                 setShowIframe,
                 }) => {
	// Global states
	const overlayStateSnapshot = useSnapshot(overlayState)
	const generalStateSnapshot = useSnapshot(generalState)

	//region States
	//Current tab
	const setCurrentTab                 = (tab) => {
		setPreviousTab(overlayStateSnapshot.currentTab)
		overlayState.currentTab = tab
	}
	const [previousTab, setPreviousTab] = useState('program')

	const [atleastOneFilterSelected, setAtleastOneFilterSelected] = useState(false)

	/*Read more - program description*/
	const [readMore, setReadMore] = useState(false)

	//region Photoswipe
	const [showPhotoswipe, setShowPhotoswipe]   = useState(false)
	const [photoswipeIndex, setPhotoswipeIndex] = useState(0)
	const images                                = [
		{
			src: 'https://file.bienici.com/photo/nexity_3_0038_38068_PRG_PHOT_38068.jpg?width=2000&height=2000',
			w:   2000,
			h:   2000,
		}, {
			src: 'https://file.bienici.com/photo/nexity_3_0038_38068_PRG_PHO2_38068.jpg?width=2000&height=2000',
			w:   2000,
			h:   2000,
		}, {
			src: 'https://file.bienici.com/photo/nexity_3_0038_38068_PRG_PHO3_38068.jpg?width=2000&height=2000',
			w:   2000,
			h:   2000,
		}, {
			src: 'https://file.bienici.com/photo/nexity_3_0038_38068_PRG_PHO4_38068.jpg?width=2000&height=2000',
			w:   2000,
			h:   2000,
		},
	]
	//endregion

	//region Contact form
	const numberOfSteps = 4

	// Show contact form
	const [showContactForm, setShowContactForm] = useState(false)

	// Current contact form step
	const [currentContactFormStep, setCurrentContactFormStep] = useState(0)
	//endregion

	//region Window width
	const [windowWidth, setWindowWidth] = useState(0)
	const isDesktop                     = useMemo(() => windowWidth > 1024, [windowWidth])

	useEffect(() => {
		setWindowWidth(window.innerWidth)
		window.addEventListener('resize', () => {
			setWindowWidth(window.innerWidth)
		})
	}, [])
	//endregion
	//endregion

	//region Handlers
	// Handle Current contact form step change
	useEffect(() => {

		if (showContactForm) {
			gsap.to('.steps', {
				xPercent: showContactForm
				          ? -100 / numberOfSteps * currentContactFormStep
				          : 0,
			})


			/*It should not be necessary but if we do not reset it, it does the animation above on '.steps' but on the '.default_overlay .content' element*/
			gsap.set('.default_overlay .content', {
				xPercent: 0,
			})
		}
	}, [
		          showContactForm, currentContactFormStep,
	          ])

	useEffect(() => {
		setCurrentContactFormStep(0)
	}, [showContactForm])

	// Handle current tab change
	useEffect(() => {
		if (overlayStateSnapshot.currentTab !== 'apartments') {
			setShowIframe(true)
		}
	}, [
		          overlayStateSnapshot.currentTab, setShowIframe,
	          ])
	//endregion

	return (
		<div
			className={styles.overlay}
		>
			<div className={styles.top}>
				{/*Tabs*/}
				<div className={styles.tabs}>
					{/*Tab - Programme*/}
					<div
						className={cn(styles.program, {
							[styles.active]: overlayStateSnapshot.currentTab === 'program',
						})}

						/*On click -> Change the current tab*/
						onClick={() => {
							setCurrentTab('program')
							setPageToShow('building-page')
						}}
					>
						{/*Icon*/}
						<svg
							className={styles.icon}
							xmlns='http://www.w3.org/2000/svg'
							viewBox='0 0 24 24'
						>
							<path
								fill='none'
								d='M0 0h24v24H0z'
							/>
							<path d='M21 19h2v2H1v-2h2V4a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v15h2V9h3a1 1 0 0 1 1 1v9zM7 11v2h4v-2H7zm0-4v2h4V7H7z'/>
						</svg>

						{/*Text*/}
						<span className={styles.text}>Vue globale</span>
					</div>


					<div
						className={cn(styles.information, {
							[styles.active]: overlayStateSnapshot.currentTab === 'program-information',
						})}

						/*On click -> Change the current tab*/
						onClick={() => {
							setCurrentTab('program-information')
							setPageToShow('building-page')
						}}
					>
						{/*Icon*/}
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='24'
							height='24'
							viewBox='0 0 24 24'
							className={styles.icon}
						>
							<path
								d='M20.41,8.41,15.58,3.58A2,2,0,0,0,14.17,3H5A2.006,2.006,0,0,0,3,5V19a2.006,2.006,0,0,0,2,2H19a2.006,2.006,0,0,0,2-2V9.83A2.006,2.006,0,0,0,20.41,8.41ZM7,7h7V9H7ZM17,17H7V15H17Zm0-4H7V11H17Z'
							/>
						</svg>


						{/*Text*/}
						<span className={styles.text}>Informations</span>
					</div>

					{/*Tab - Logements*/}
					<div
						className={cn(styles.apartments, {
							[styles.active]: overlayStateSnapshot.currentTab === 'apartments',
						})}

						/*On click -> Change the current tab*/
						onClick={() => {
							if (overlayStateSnapshot.currentTab !== 'apartments') {
								setCurrentTab('apartments')
								setPageToShow('building-page')
							}
						}}
					>
						{/*Icon*/}
						<svg
							className={styles.icon}
							xmlns='http://www.w3.org/2000/svg'
							viewBox='0 0 24 24'
						>
							<path
								fill='none'
								d='M0 0h24v24H0z'
							/>
							<path d='M20.083 10.5l1.202.721a.5.5 0 0 1 0 .858L12 17.65l-9.285-5.571a.5.5 0 0 1 0-.858l1.202-.721L12 15.35l8.083-4.85zm0 4.7l1.202.721a.5.5 0 0 1 0 .858l-8.77 5.262a1 1 0 0 1-1.03 0l-8.77-5.262a.5.5 0 0 1 0-.858l1.202-.721L12 20.05l8.083-4.85zM12.514 1.309l8.771 5.262a.5.5 0 0 1 0 .858L12 13 2.715 7.429a.5.5 0 0 1 0-.858l8.77-5.262a1 1 0 0 1 1.03 0z'/>
						</svg>

						{/*Text*/}
						<span className={styles.text}>Logements</span>
					</div>

					{/*Tab - Quartier*/}
					<div
						className={cn(styles.neighborhood, {
							[styles.active]: overlayStateSnapshot.currentTab === 'neighborhood',
						})}

						/*On click -> Change the current tab*/
						onClick={() => {
							setCurrentTab('neighborhood')
							selectedApartmentState.apartmentData.current = null
						}}
					>
						{/*Icon*/}
						<svg
							className={styles.icon}
							xmlns='http://www.w3.org/2000/svg'
							viewBox='0 0 24 24'
						>
							<path
								fill='none'
								d='M0 0h24v24H0z'
							/>
							<path d='M11 17.938A8.001 8.001 0 0 1 12 2a8 8 0 0 1 1 15.938v2.074c3.946.092 7 .723 7 1.488 0 .828-3.582 1.5-8 1.5s-8-.672-8-1.5c0-.765 3.054-1.396 7-1.488v-2.074zM12 12a2 2 0 1 0 0-4 2 2 0 0 0 0 4z'/>
						</svg>

						{/*Text*/}
						<span className={styles.text}>Quartier</span>
					</div>
				</div>
				{!showContactForm
				 ? <div
					 className={cn(styles.default_overlay, 'default_overlay', {
						 [styles.is_contact_us]: overlayStateSnapshot.currentTab === 'price-and-plan',
						 [styles.is_apartment]:  overlayStateSnapshot.currentTab === 'apartments' && atleastOneFilterSelected,
					 })}
				 >
					 {/*region Content*/}
					 <div
						 className={cn(styles.content, 'content')}
					 >
						 {/*Tab - Programme - Content*/}
						 <div
							 className={styles.program_content}
							 style={{
								 display: (
									          isDesktop && overlayStateSnapshot.currentTab === 'program'
								          ) || overlayStateSnapshot.currentTab === 'program-information'
								          ? 'flex'
								          : 'none',
							 }}
						 >
							 {/*Main apartment information*/}
							 <div className={styles.main_information}>
								 {/*Apartment name*/}
								 <span className={styles.apartment_name}>Ovation Magellan</span>
								 {/*Sub description*/}
								 <span className={styles.sub_description}>Appartements neufs à vendre à Colombes /92</span>
							 </div>

							 {/*First trimester 2024*/}
							 <div className={styles.first_trimester}>
								 {/*Icon - calendar*/}
								 <svg
									 xmlns='http://www.w3.org/2000/svg'
									 viewBox='0 0 24 24'
								 >
									 <path
										 fill='none'
										 d='M0 0h24v24H0z'
									 />
									 <path d='M17 3h4a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h4V1h2v2h6V1h2v2zM4 9v10h16V9H4zm2 4h5v4H6v-4z'/>
								 </svg>

								 <span className={styles.first_trimester_text}>1er trimestre 2024</span>
							 </div>

							 {/*Apartment description*/}
							 <div
								 className={cn(styles.apartment_description, {[styles.full]: readMore})}
							 >
								 <p>Découvrez notre nouvelle résidence <strong>Ovation Magellan - Ilot 14</strong> située dans le futur quartier exceptionnel de l'Arc sportif. C'est le moment de devenir propriétaire à 14min de Paris Saint-Lazare entre Paris et La Défense !</p>
								 <p>Découvrez une nouvelle terre de valeurs au pied du transilien (ligne L vers Paris Saint-Lazare en 14 minutes) et du futur tramway T1. Un nouveau quartier aux nombreux atouts : des parcs, de nombreux commerces, des équipements scolaires (crèche, écoles...), des infrastructures sportives (un équipement multisports, un practice de golf), les Hautes Serres de Colombes dédiées à l'agriculture urbaine...</p>
								 <p>Dans ce futur nouveau quartier emblématique de l'ouest parisien, à quelques minutes du centre-ville, les résidences Ovation Magellan occupent une place de choix. Au cœur d'un grand Parc paysager, la ferme urbaine en aquaponie pour voisine, les appartements se déclinent du studio au 5 pièces duplex avec pour la plupart de magnifiques espaces extérieurs (balcons, terrasses, jardins d'hiver...).</p>

								 {!readMore && <span
									 className={styles.read_more}

									 onClick={() => {
										 setReadMore(true)
									 }}
								 >Lire la suite</span>}
							 </div>

							 {/*Available lot*/}
							 <div className={styles.available_lot}>
								 <span>Choisissez votre futur logement <br/>parmi les <strong>123</strong> lots disponibles</span>
								 {/*Choose*/}
								 <span className={styles.choose}>Faire mon choix</span>
							 </div>

							 {/*Gallery*/}
							 <div className={styles.gallery}>
								 {/*Title*/}
								 <h4 className={styles.title}>Galerie :</h4>

								 {/*Swiper*/}
								 <Swiper
									 className={styles.swiper}

									 spaceBetween={16}
									 slidesPerView={1.2}

									 /*Modules*/
									 modules={[Pagination]}

									 // pagination={true}
									 pagination={{
										 clickable:         true,
										 type:              'bullets',
										 bulletClass:       cn('swiper-pagination-bullet', styles.bullet),
										 bulletActiveClass: cn('swiper-pagination-bullet-active', styles.bullet_active),
									 }}
								 >
									 {images.map((image, index) => (
										 <SwiperSlide
											 key={index}
											 onClick={() => {
												 // Set photoswipe index
												 setPhotoswipeIndex(index)

												 // Open photoswipe
												 setShowPhotoswipe(true)
											 }}
										 >
											 {/*Icon*/}
											 <div className={styles.icon}>
												 <FaCamera className={styles.camera_icon}/>
											 </div>

											 <img
												 src={image.src}
												 alt='Ovation Magellan - Ilot 14'
											 />
										 </SwiperSlide>
									 ))}
								 </Swiper>
							 </div>


							 {/*Caractéristiques et équipements*/}
							 <div className={styles.characteristics}>
								 {/*Title*/}
								 <h4 className={styles.title}>Caractéristiques et équipements</h4>

								 {/*List*/}
								 <div className={styles.list}>
									 {Array.from([
										             {
											             name: 'Ascenseur',
											             icon: EquipmentElevator,
										             }, {
											       name: 'Digicode',
											       icon: EquipmentDigicode,
										       }, {
											       name: 'Interphone',
											       icon: EquipmentInterphone,
										       }, {
											       name: 'Cuis. équipée',
											       icon: EquipmentEquipedKitchen,
										       }, {
											       name: 'Ch. collectif',
											       icon: EquipmentCollectiveHeating,
										       }, {
											       name: 'Espaces verts',
											       icon: EquipmentGreenSpaces,
										       }, {
											       name: 'Terrasse',
											       icon: EquipmentTerrace,
										       },
									             ])
									       .map((item, index) => (
										       <div
											       className={styles.item}
											       key={index}
										       >
											       {/*Image*/}
											       <img
												       src={item.icon}
												       alt={item.name}
												       className={styles.icon}
											       />

											       {/*Text*/}
											       <span className={styles.text}>{item.name}</span>
										       </div>
									       ))}
								 </div>

								 {/*Options description*/}
								 <div className={styles.options_description}>
									 <span>* option cuisine équipée</span>
								 </div>
							 </div>

							 {/*Transports à proximité*/}
							 <div className={styles.nearby_transport}>
								 {/*Title*/}
								 <h4 className={styles.title}>Les transports à proximité</h4>

								 {/*List*/}
								 <div className={styles.list}>
									 {Array.from([
										             {
											             name:  'Ligne L',
											             image: TransportLineL,
										             }, {
											       name:  'Bus 45',
											       image: TransportBus45,
										       },
									             ])
									       .map((item, index) => (
										       <div
											       className={styles.item}
											       key={index}
										       >
											       {/*Image*/}
											       <img
												       src={item.image}
												       alt={item.name}
												       className={styles.image}
											       />

											       {/*Text*/}
											       <span className={styles.text}>{item.name}</span>
										       </div>
									       ))}
								 </div>
							 </div>

							 {/*Les plus de Nexity*/}
							 <div className={styles.nexity_plus}>
								 {/*Title*/}
								 <h4 className={styles.title}>Les plus de Nexity</h4>

								 {/*List*/}
								 <div className={styles.list}>
									 {Array.from([
										             {
											             title:       'Eugénie : l\'application pour logement connecté par Nexity !',
											             description: 'Contrôlez votre logement et connectez-vous à la résidence à tout moment.\n' + 'Eugénie apporte confort, efficacité et sérénité au quotidien pour votre famille.',
											             color:       '#A594FF',
											             icon:        EugenieIcon,
										             }, {
											       title:       'Gamme Green, votre logement plus éco-responsable :',
											       description: 'Optimisation des déchets, économies d\'eau et d\'énergie, matériaux et fabrication à l\'empreinte écologie limitée.',
											       color:       '#7BFF9F',
											       icon:        EquipmentGreenSpaces,
										       },
									             ])
									       .map((item, index) => (
										       <div
											       className={styles.item}
											       key={index}

											       style={{backgroundColor: item.color}}
										       >
											       {/*Icon*/}
											       <img
												       src={item.icon}
												       alt={item.title}
												       className={styles.icon}
											       />

											       {/*Text*/}
											       <span className={styles.title}>{item.title}</span>

											       {/*Description*/}
											       <span className={styles.description}>{item.description}</span>
										       </div>
									       ))}
								 </div>
							 </div>

							 {/*Contact us*/}
							 <div className={styles.contact_us}>
								 {/*Text*/}
								 <span className={styles.text}>Pour obtenir des informations complémentaires sur ce programm immobilier, contactez nos conseillers :</span>

								 {/*Button*/}
								 <button
									 className={styles.button}
									 onClick={() => {
										 setCurrentTab('contact-us')
									 }}
								 >Nous contacter
								 </button>

							 </div>
						 </div>

						 {/*Tab - Logements - Content*/}
						 <ApartmentOverlay
							 pageToShow={pageToShow}
							 setPageToShow={setPageToShow}
							 setShowIframe={setShowIframe}
							 setShowContactForm={setShowContactForm}
							 setAtleastOneFilterSelected={setAtleastOneFilterSelected}
						 />

						 {/*Tab - Quartier - Content*/}
						 <OverlayNeighborhoodContent/>

						 {/*Tab - Quartier - Content*/}
						 <OverlayPriceAndPlan
							 goBackToPreviousTab={() => setCurrentTab(previousTab)}
						 />

						 {/*Tab - Quartier - Content*/}
						 <OverlayContactUs
							 goBackToPreviousTab={() => setCurrentTab(previousTab)}
						 />
					 </div>
					 {/*endregion*/}
				 </div>
				 : <div
					 className={cn(styles.contact_form, {
						 [styles.is_last_step]: currentContactFormStep === numberOfSteps - 1,
					 })}
				 >

					 {/*Top*/}
					 <div className={styles.top}>
						 {/*Title*/}
						 <span className={styles.title}>Prix et plan</span>
						 {/*Close button*/}
						 <span
							 className={styles.close_button}
							 onClick={() => {
								 setShowContactForm(false)
								 setPageToShow('building-page')
							 }}
						 >Fermer</span>
					 </div>

					 {/*Steps*/}
					 <div
						 className={cn(styles.steps, 'steps')}

						 style={{
							 width: `${100 * numberOfSteps}%`,
						 }}
					 >
						 {/*Step 1*/}
						 <div className={styles.step_1}>
							 {/*Title*/}
							 <h4 className={styles.title}>Pour être accompagné dans vos recherches, nous vous proposons de :</h4>

							 {/*Inputs*/}
							 <div className={styles.main_content}>

								 {/*Last name*/}
								 <label className={styles.last_name}>
									 <span className={styles.text}>Votre nom</span>
									 {/*Input*/}
									 <input
										 type={'text'}
										 className={styles.input}
									 />
								 </label>

								 {/*First name*/}
								 <label className={styles.first_name}>
									 <span className={styles.text}>Votre prénom</span>
									 {/*Input*/}
									 <input
										 type={'text'}
										 className={styles.input}
									 />
								 </label>

								 {/*Email address*/}
								 <label className={styles.email_address}>
									 <span className={styles.text}>Votre email</span>
									 {/*Input*/}
									 <input
										 type={'text'}
										 className={styles.input}
									 />
								 </label>

								 {/*Phone number*/}
								 <label className={styles.phone_number}>
									 <span className={styles.text}>Votre téléphone</span>
									 {/*Input*/}
									 <input
										 type={'text'}
										 className={styles.input}
									 />
								 </label>

								 {/*Checkbox accept data*/}
								 <label className={styles.checkbox_accept_data}>
									 {/*Checkbox*/}
									 <input type='checkbox'/>
									 {/*Text*/}
									 <span className={styles.text}>J'accepte que mes données soient traitées par Nexity, et/ou ses partenaires dans le cadre de ma demande et de la relation commerciale qui pourrait en découler</span>
								 </label>
							 </div>

							 {/*Next button*/}
							 {/*TODO: Disable if all inputs are not filled*/}
							 <button
								 className={styles.next_button}
								 onClick={() => setCurrentContactFormStep(1)}
							 >Suivant
							 </button>
						 </div>

						 {/*Step 2*/}
						 <div className={styles.step_2}>
							 {/*Title*/}
							 <h4 className={styles.title}>Pour obtenir le prix et plan de ce logement et être contacté(e) par un de nos conseillers, précisez-nous :</h4>

							 {/*Main content*/}
							 <div className={styles.main_content}>
								 {/*Choice - Receive personalised offers from Nexity group*/}
								 <div className={styles.choice_receive_personalised_offers}>
									 {/*Text*/}
									 <span className={styles.text}>Recevoir les offres personnalisées du groupe Nexity</span>

									 {/*Choices*/}
									 <div className={styles.choices}>
										 {/*Choice - Yes*/}
										 <label className={styles.choice}>
											 {/*Radio*/}
											 <input
												 type='radio'
												 name='receive_personalised_offers'
											 />
											 {/*Text*/}
											 <span className={styles.text}>Oui</span>
										 </label>

										 {/*Choice - No*/}
										 <label className={styles.choice}>
											 {/*Radio*/}
											 <input
												 type='radio'
												 name='receive_personalised_offers'
											 />
											 {/*Text*/}
											 <span className={styles.text}>Non</span>
										 </label>
									 </div>
								 </div>

								 {/*Choice - Receive personalised offers from Nexity partners*/}
								 <div className={styles.choice_receive_personalised_partners_offers}>
									 {/*Text*/}
									 <span className={styles.text}>Recevoir les offres personnalisées des partenaires du groupe Nexity</span>

									 {/*Choices*/}
									 <div className={styles.choices}>
										 {/*Choice - Yes*/}
										 <label className={styles.choice}>
											 {/*Radio*/}
											 <input
												 type='radio'
												 name='receive_personalised_offers'
											 />
											 {/*Text*/}
											 <span className={styles.text}>Oui</span>
										 </label>

										 {/*Choice - No*/}
										 <label className={styles.choice}>
											 {/*Radio*/}
											 <input
												 type='radio'
												 name='receive_personalised_offers'
											 />
											 {/*Text*/}
											 <span className={styles.text}>Non</span>
										 </label>
									 </div>
								 </div>

								 {/*Legal notices*/}
								 <div className={styles.legal_notices}>
									 <span className={styles.content}>Nexity, en sa qualité de responsable de traitement, réalise des traitements de données à caractère personnel. Ces traitements sont rendus nécessaires pour répondre à votre demande de contact et sont fondés sur votre consentement préalable. Les données collectées sont destinées aux services concernés du groupe Nexity, et le cas échéant à ses sous-traitants et prestataires. Les sous-traitants et prestataires en question sont soumis à une obligation de confidentialité et ne peuvent utiliser vos données qu'en conformité avec nos dispositions contractuelles et la législation applicable. Elles sont conservées pendant une durée ne pouvant excéder 3 ans à compter du dernier contact de votre part. Vous avez la possibilité d'exercer vos droits pour accéder, rectifier, effacer vos données, limiter leurs traitements, vous y opposer et demander la portabilité de celles-ci. Vous pouvez également définir des directives relatives à la conservation, à l'effacement et à la communication de vos données à caractère personnel après votre décès. Ces droits s'exercent via le lien suivant : https://app.witik.io/fr/form/nexity/exercice-des-droits-rgpd ou par courrier postal à l'attention du Délégué à la Protection des Données (DPO) - Nexity SA - 19 Rue de Vienne - TSA 50029 - 75801 PARIS Cedex 08, accompagné d’une copie d’un titre d’identité. Vous pouvez également adresser une réclamation auprès de la Commission Nationale de l'Informatique et des Libertés - 3 place de Fontenoy - TSA 80715 - 75334 PARIS Cedex 07. Enfin, nous vous informons de l'existence d'une liste d'opposition au démarchage téléphonique prévue par le Code de la Consommation à l'article L.223-1.</span>
								 </div>
							 </div>

							 {/*Next button*/}
							 <button
								 className={styles.next_button}
								 onClick={() => {
									 setCurrentContactFormStep(2)
									 setPageToShow('plan-page')
								 }}
							 >Valider
							 </button>
						 </div>

						 {/*Step 4*/}
						 <div
							 className={cn(styles.step_3, styles.last_step)}
						 >
							 {/*Main content*/}
							 <div className={styles.main_content}>
								 {/*Apartment information*/}
								 <div className={styles.apartment_information}>
									 {/*Package number*/}
									 <span className={styles.package_number}>Lot n°2234</span>

									 {/*Type*/}
									 <span className={styles.type}>Appartement</span>

									 {/*Data*/}
									 <div className={styles.data}>
										 {/*Rooms*/}
										 <span className={styles.rooms}>4 pièces</span>

										 {/*Surface*/}
										 <span className={styles.surface}>75 m²</span>

										 {/*Stage*/}
										 <span className={styles.stage}>Étage 5</span>

										 {/*Parking*/}
										 <span className={styles.parking}>Parking</span>
									 </div>

									 {/*Price*/}
									 <div className={styles.price}>
										 {/*Value*/}
										 <span className={styles.value}>475 540 €</span>

										 {/*Tax type*/}
										 <span className={styles.tax_type}>TTC</span>
									 </div>
								 </div>

								 {/*Thank*/}
								 <span className={styles.thank}>Merci, un de nos conseillers va vous contacter prochainement.</span>

								 {!generalStateSnapshot.isDesktop && <Plan_Page/>}

								 {/*Download the plan in PDF*/}
								 <a
									 href={'/Exemple_PDF_Nexity.pdf'}
									 target={'_blank'}
									 className={styles.download_plan_in_pdf}
								 >Télécharger le plan (pdf)</a>
							 </div>

							 {/*Back button*/}
							 <span
								 className={styles.go_back_to_3d_view}
								 onClick={() => {
									 setShowContactForm(false)
									 setPageToShow('room-page')
								 }}
							 >Retour à la vue d'ensemble
							 </span>
						 </div>
					 </div>
				 </div>}
			</div>

			{/*Footer*/}
			<div className={styles.footer}>
				{/*Home icon*/}
				<FaHome className={styles.home_icon}/>

				{/*Immobilier*/}
				<span>Immobilier</span>

				{/*Triple dot*/}
				<div className={styles.triple_dot}>...</div>

				{/*Ovation Magellan*/}
				<div className={styles.current_tab}>
					<span>Ovation Magellan</span>
					<FaCaretLeft className={styles.icon}/>
				</div>
			</div>

			<PhotoSwipe
				items={images}
				onClose={() => setShowPhotoswipe(false)}
				isOpen={showPhotoswipe}
				options={{
					index: photoswipeIndex,
				}}
			/>
		</div>
	)
}

export default Overlay
