import {Plane, shaderMaterial} from '@react-three/drei'
import * as THREE from 'three'
import {extend} from '@react-three/fiber'
import {useRef} from 'react'
import {button, useControls} from 'leva'
import {debugState} from '../../../state/debug'
import {useSnapshot} from 'valtio'
import {selectedProgramState} from '../../../state/selectedProgram'

export default function ApartmentFloor() {
    const dotMaterial = useRef()

    // Global state
    const debugStateSnapshot = useSnapshot(debugState)
    const selectedProgramStateSnapshot = useSnapshot(selectedProgramState)

    useControls('Switch grid type', {
        switch: button(() => {
            debugState.gridModeCSS = !debugState.gridModeCSS
        }),
    })

    return (
        <>
            {!debugStateSnapshot.gridModeCSS && !selectedProgramStateSnapshot.shown && <Plane
                args={[1000, 1000]}
                position-y={-.01}
                rotation-x={-Math.PI / 2}
            >
                <dotMaterial
                    ref={dotMaterial}
                    key={DotMaterial.key}
                    side={THREE.DoubleSide}
                    transparent={true}
                />
            </Plane>}
        </>
    )
}

// language=GLSL format=false
const DotMaterial = shaderMaterial({
    uDistance: 50,
}, `varying vec2 vUv;
varying vec3 vPosition;
void main() {
    vUv = uv;
    vPosition = position;
    gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
}
`, `uniform float uDistance;
varying vec2 vUv;
varying vec3 vPosition;
void main() {
    float gridSize = 2.0;

    // Lines
    float lineSize = .02;
    vec3 linePosition = mod(vPosition + lineSize / 2.0, gridSize);
    float lines1 = step(.5, linePosition.x + .5 - lineSize);
    float lines2 = step(.5, linePosition.y + .5 - lineSize);
    // Points
    vec3 points =  vec3(lines1 * lines2);
    float pointsAlpha = 1.0 - points.x;

    // Stars
    vec3 starPosition = mod(vPosition + .5, gridSize);
    float strength = 1.0 / (distance(vec2(starPosition.x, (starPosition.y - 0.5) * 5.0 + 0.5), vec2(.5)));
    strength *= 0.1 / (distance(vec2(starPosition.y, (starPosition.x - 0.5) * 5.0 + 0.5), vec2(.5)));
    //     float starAlpha = strength * 1.0;
    float starAlpha = strength * .01;

    // Distance alpha
    float dist = length(vPosition);
    float alpha = 1. - smoothstep(uDistance -200., uDistance, dist);

    // Final alpha
    float finalAlpha = ((pointsAlpha + starAlpha) * .1) * alpha;

    // Result
    gl_FragColor = vec4(vec3(.2), finalAlpha * 5.);
}`)

extend({DotMaterial})
